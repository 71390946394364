import React from 'react';
import { TwoCol } from '../style/TwoCol';
import styled from 'styled-components';
import { CouchedDiv, pagePadding } from '../style/PageHolder';
import { mainCouch } from '../style/mainCouch';
import { Logo } from '../content/vector/Logo';
import { ParallaxContainer } from '@dontsave/scroll-effects';
import { Color } from '../style/Color';
import { graphql, useStaticQuery } from 'gatsby';
import { Info } from '../graphql/types';
import parser from 'html-react-parser';

export type Props = {};

const Header = styled.div`
	height: 600px;
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;

	margin-bottom: 40px;

	section.header {
		background-color: ${Color.BG_FADED};
		color: white;
		background-size: 100% 100%;
		padding: 30px;
		margin-bottom: 0px;
		display: flex;
		align-content: center;
		align-items: center;

		@media (max-width: 768px) {
			flex-wrap: wrap;
			padding: 20px;
		}

		& > div {
			padding: 10px;
			@media (max-width: 768px) {
				width: 100%;
			}
		}
	}

	.index-logo {
		width: 200px;
		@media (max-width: 400px) {
			width: 100px;
		}
		margin: auto;
		padding-top: 20px;

		padding: 30px;
		border: 2px solid ${Color.HILIGHT};
	}

	.desc p {
		font-size: 27px;
		line-height: 32px;
		margin-bottom: 0px;
		@media (max-width: 768px) {
			font-size: 22px;
			line-height: 22px;
		}
	}
`;

const ParallaxImg = styled.div`
	width: 100%;
	height: 1000px;
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		object-position: 50% 50%;
	}
`;

const InfoSection = styled.div`
	.h4 {
		font-weight: bold;
	}
`;

const Component: React.FC<Props> = props => {
	const {
		allMarkdownRemark: { edges: info },
	}: Info = useStaticQuery(graphql`
		query Info {
			allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/info/" } }) {
				edges {
					node {
						frontmatter {
							title
						}
						html
					}
				}
			}
		}
	`);

	const about = info.find(i => i.node.frontmatter.title === `About`);
	const header = info.find(i => i.node.frontmatter.title === `Header`);

	return (
		<div>
			<ParallaxContainer
				amount={500}
				style={{
					position: `absolute`,
					width: `100%`,
					top: 100,
					left: 0,
					height: 600,
					zIndex: -1,

					overflow: `hidden`,
				}}
			>
				<ParallaxImg>
					<img src={'/img/26 Broadway (sliced).jpg'} />
				</ParallaxImg>
			</ParallaxContainer>
			<CouchedDiv>
				<Header>
					<section className={`header`}>
						<div className={`logo-holder`}>
							<Logo className={`index-logo`} />
						</div>
						<div className={`desc`}>{parser(header.node.html)}</div>
					</section>
				</Header>
				<section>{parser(about.node.html)}</section>
			</CouchedDiv>
		</div>
	);
};

export default Component;
